import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import Vue from 'vue'
import { nextTick } from 'vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/universalcity',
    name: 'universalcity',
    meta: { layout: 'default', title: 'Universal City' },
    component: () => import('../views/UniversalCity.vue')
  },
  {
    path: '/chelsea-eagles',
    name: 'chelsea-eagles',
    meta: { layout: 'default', title: 'Chelsea Eagles' },
    component: () => import('../views/ChelseaEagles.vue')
  },
  {
    path: '/Bravo',
    name: 'Bravo',
    meta: { layout: 'default', title: 'Bravo' },
    component: () => import('../views/BravoPage.vue')
  },
  {
    path: '/driver',
    name: 'driver',
    meta: { layout: 'default', title: 'DriverPage' },
    component: () => import('../views/DriverPage.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    meta: { layout: 'default', title: 'admin' },
    component: () => import('../views/AdminPage.vue')
  },
  {
    path: '/belwood',
    name: 'Belwood',
    meta: { layout: 'default', title: 'Belwood' },
    component: () => import('../views/BelwoodPage.vue')
  },
  {
    path: '/wilde',
    name: 'Wilde',
    meta: { layout: 'default', title: 'Wilde' },
    component: () => import('../views/WildePage.vue')
  }
]


const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    document.title = to.meta.title || 'Page'
  })
})

export default router
